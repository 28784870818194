<template>
  <div class="empowering-language-container">
    <div class="empowering-language-title">
      Empowering language learning for institutions
    </div>
    <div class="empowering-language-tiles">
      <div class="row">
        <div
          class="col-lg-3 col-12 empowering-language-tile-wrapper"
          v-for="(tile, i) in getEmpoweringLanguage"
          :key="i"
        >
          <div class="empowering-language-tile">
            <div class="empowering-language-tile-icon">
              <i :class="tile.icon"></i>
            </div>
            <div class="empowering-language-tile-title">{{ tile.title }}</div>
            <div class="empowering-language-tile-title">{{ tile.title1 }}</div>
            <div class="empowering-language-tile-description-wrapper">
              <div class="empowering-language-tile-description">
                {{ tile.description }}
              </div>
              <div class="empowering-language-tile-description">
                {{ tile.description1 }}
              </div>
              <div class="empowering-language-tile-description">
                {{ tile.description2 }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <md-filled-tonal-button
      class="get-started-button"
      @click="$router.push('/try-comligo-for-free')"
    >
      Book a demo class
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmpoweringLanguage",
  computed: {
    ...mapGetters(["getEmpoweringLanguage"]),
  },
};
</script>

<style></style>
