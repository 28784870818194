<template>
  <div class="our-impact-container">
    <div class="our-impact-title">Our impact</div>
    <div class="row">
      <div class="col-lg-4 col-12">
        <div class="our-impact-col-1">
          <div class="our-impact-top-title">5,000+</div>
          <div class="our-impact-bottom-title">students</div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="our-impact-col-2">
          <div class="our-impact-top-title">100+</div>
          <div class="our-impact-bottom-title">schools and organizations</div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="our-impact-col-3">
          <div class="our-impact-top-title">Approved</div>
          <div class="our-impact-bottom-title">
            by the Departments of Education of 10 states
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OurImpact",
};
</script>

<style></style>
