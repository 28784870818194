<template>
  <div class="individual-courses-container">
    <div class="partner-statements-badge">For Individuals</div>
    <div class="individual-courses-title">
      Learn Spanish with a Course That Fits You
    </div>
    <div class="individual-courses-tiles">
      <div class="row">
        <div
          class="col-lg-3 col-12 mb-5"
          v-for="(tile, i) in getIndividualCourses"
          :key="i"
        >
          <div class="individual-courses-tiles">
            <div class="individual-courses-tile-icon">
              <i :class="tile.icon"></i>
            </div>
            <div class="individual-courses-tile-title">{{ tile.title }}</div>
            <div class="individual-courses-tile-description">
              {{ tile.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <md-filled-tonal-button
      class="get-started-button"
      @click="$router.push('/try-comligo-for-free')"
    >
      Book Your Free Trial Now!
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
    <div class="individual-courses-book-title">
      Get your free Spanish class now, one click away, no credit card required!
    </div>
  </div>
</template>

<script>
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
import { mapGetters } from "vuex";

SwiperCore.use([Autoplay, Pagination, Navigation]);

export default {
  name: "PartnersStatements",
  components: {},
  computed: {
    ...mapGetters(["getTestimonials", "getIndividualCourses"]),
  },
};
</script>

<style></style>
