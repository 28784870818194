<template>
  <v-app>
    <v-main>
      <notification v-if="getNotification.show" />
      <router-view />
      <mobileCTA />
      <cookieDialogue />
    </v-main>
  </v-app>
</template>

<script>
import "@/assets/styles/app.css";
import "@/assets/styles/pagination.css";
import cookieDialogue from "./components/cookieDialogue.vue";
import mobileCTA from "./components/mobileCTA.vue";
import notification from "./components/notification.vue";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "App",
  components: {
    cookieDialogue,
    notification,
    mobileCTA,
  },
  watch: {
    loading(val) {
      if (!val && !this.blocked()) {
        this.loadGA();
      }
    },
    $route() {
      this.storeGclid();
    },
  },
  computed: {
    ...mapGetters(["getNotification"]),
  },
  mounted() {
    this.getIPList();
  },
  data: () => ({
    loading: true,
    ips: [],
  }),
  methods: {
    getIp() {
      axios
        .get("https://api.ipify.org")
        .then((response) => {
          localStorage.ipInfo = response?.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getIPList() {
      axios
        .get(
          `https://cdn.contentful.com/spaces/8nqnktj51aoq/environments/master/entries/4AMgwRTWpVHg9YmXY5DOoF?access_token=njkIU16uuU7MPa5hOvzUO4deV6DFYht3xdwj6KUge-k`
        )
        .then((response) => {
          this.ips = response?.data?.fields?.blocked;
          if (!localStorage.ipInfo) {
            this.getIp();
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    blocked() {
      const ip = localStorage.ipInfo ? localStorage?.ipInfo : "";
      return this.ips.includes(ip);
    },
    loadGA() {
      const script = document.createElement("script");
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          // eslint-disable-next-line no-undef
          dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-VYGVMFBWS0");
      };
      script.src = "https://www.googletagmanager.com/gtag/js?id=G-VYGVMFBWS0";
      document.head.appendChild(script);
    },
    storeGclid() {
      if (this.$route?.query?.gclid) {
        localStorage.gcl = this.$route?.query?.gclid;
      }
    },
  },
};
</script>
<style lang="scss"></style>
