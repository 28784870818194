<template>
  <div>
    <div class="transform-language-inner-container">
      <div class="transform-language-title">
        Transform Your Language Learning
      </div>
      <div class="transform-language-description">
        Spanish is one of the best languages to learn, and Comligo makes it
        easy! Whether for personal growth or for your institution, our
        structured curriculum with guided programs helps you succeed.
      </div>
      <div>
        <md-filled-tonal-button
          class="get-started-button"
          @click="$router.push('/try-comligo-for-free')"
        >
          Book Your Free Trial Now
          <i class="mdi mdi-arrow-right"></i>
        </md-filled-tonal-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TransformLanguage",
};
</script>

<style></style>
