<template>
  <div class="notification-wrapper">
    <i class="mdi mdi-close notification-close" @click="hideNotification()"></i>
    <v-alert
      :text="getNotification.message"
      :title="getNotification.title"
      :type="getNotification.type"
    ></v-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  name: "notificationComponent",
  computed: {
    ...mapGetters(["getNotification"]),
    ...mapState(["notification"]),
  },
  methods: {
    ...mapMutations(["setNotification"]),
    hideNotification() {
      this.setNotification({
        show: false,
        title: "",
        message: "",
        type: "",
      });
    },
  },
};
</script>

<style></style>
