<template>
  <div class="mobile-cta-section">
    <md-filled-tonal-button
      class="get-started-button"
      @click="$router.push('/try-comligo-for-free')"
    >
      Book Your Free Trial Now
      <i class="mdi mdi-arrow-right"></i>
    </md-filled-tonal-button>
  </div>
</template>

<script>
export default {
  name: "mobileCTA",
};
</script>

<style></style>
