<template>
  <div class="footer-container">
    <div class="row">
      <div class="col-lg-2 col-12 footer-cols">
        <img
          class="footer-logo"
          :src="require('@/assets/images/logoColoredWhite.png')"
          alt="footer logo image"
        />
        <div class="footer-col-1-text">CONNECT</div>
        <div class="footer-col-1-text">LEARN</div>
        <div class="footer-col-1-text">GROW</div>
        <div class="footer-col-1-button">
          <md-filled-tonal-button
            class="record-list-read-more-button"
            @click="$router.push('/book-demo')"
          >
            Book Your Free Trial Now
            <i class="mdi mdi-arrow-right"></i>
          </md-filled-tonal-button>
        </div>
      </div>
      <div class="col-lg-1 col-4 footer-cols hidden-footer-cols">
        <div
          class="footer-cols-title"
          @click="$router.push('/spanish-courses-overview')"
        >
          Courses
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Adults
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Teenagers
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-courses-overview')"
        >
          For Kids
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div
          class="footer-cols-title"
          @click="$router.push('/private-students')"
        >
          For Individuals
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title">For Organizations</div>
        <div class="footer-cols-links" @click="$router.push('/k-12-schools')">
          For K-12 education
        </div>
        <div class="footer-cols-links" @click="$router.push('/ib-schools')">
          For IB schools
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/higher-education')"
        >
          For higher education
        </div>
        <div class="footer-cols-links" @click="$router.push('/private-sector')">
          For private sector
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/public-sector-ngos')"
        >
          For public sector and non-profits
        </div>
      </div>
      <div class="col-lg-1 col-4 footer-cols hidden-footer-cols">
        <div
          class="footer-cols-title"
          @click="$router.push('/spanish-course-pricing')"
        >
          Pricing
        </div>
      </div>
      <div class="col-lg-2 col-4 footer-cols hidden-footer-cols">
        <div class="footer-cols-title" @click="$router.push('/about-comligo')">
          About us
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/about-comligo/academic-team')"
        >
          Our Academic team
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/about-comligo/teachers')"
        >
          Our teachers
        </div>
      </div>
      <div class="col-lg-1 col-4 footer-cols hidden-footer-cols">
        <div
          class="footer-cols-title"
          @click="$router.push('/spanish-learning-resources')"
        >
          Resources
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-articles')"
        >
          Articles
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-resources')"
        >
          Guides
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/spanish-learning-ebooks')"
        >
          Ebooks
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/comligo-spanish-events')"
        >
          Events
        </div>
      </div>
      <div class="col-lg-1 col-4 footer-cols hidden-footer-cols">
        <div
          class="footer-cols-title"
          @click="$router.push('/contact-comligo')"
        >
          Contact us
        </div>
        <div
          class="footer-cols-links"
          @click="$router.push('/contact-comligo')"
        >
          Contact
        </div>
        <div class="footer-cols-links" @click="$router.push('/faqs')">FAQs</div>
      </div>
    </div>
    <div class="mobile-footer-cols">
      <v-list class="mobile-footer-list-item mobile-footer-items-wrapper">
        <div
          class="mobile-footer-list-items"
          :style="
            item.border
              ? { borderTop: '1px solid #ffffff2e', marginTop: '10vw' }
              : ''
          "
          v-for="item in footer"
          :key="item.title"
        >
          <v-list-group v-model="item.active" no-action v-if="item?.items">
            <template v-slot:activator="{ props }">
              <v-list-item
                :append-icon="item.active ? 'mdi-menu-up' : 'mdi-menu-down'"
              >
                <v-list-item-title
                  v-bind="props"
                  class="mobile-footer-list-item-title"
                  @click="item.active = !item.active"
                  pre
                >
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <v-list-item
              class="mobile-footer-items"
              v-for="(subItem, index) in item.items"
              :key="index"
            >
              <v-list-item-title @click="$router.push(subItem.link)">
                {{ subItem.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
          <div
            class="mobile-footer-item-title"
            @click="$router.push(item?.link)"
            v-else
          >
            {{ item?.title }}
          </div>
        </div>
      </v-list>
    </div>
    <div class="footer-copyright">
      <div class="footer-cols-links all-right-mobile">
        © 2024 Comligo. All rights reserved.
      </div>
      <div class="row m-auto footer-cols-links-row">
        <div
          class="footer-cols-links col-lg-6 col-12"
          @click="$router.push('/privacy-policy')"
        >
          Policies
        </div>
        <div class="footer-cols-links col-lg-6 col-12" @click="setDialog(true)">
          Cookies Consent Manager
        </div>
      </div>
      <div class="footer-icons-group">
        <div class="footer-icons-button">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Facebook1.png?alt=media&token=6fd2577b-ea81-4609-a01f-5c2859cec84f"
            alt="facebook-logo"
            class="footer-icons-img"
            @click="openLink('https://www.facebook.com/ComligoSpanish')"
          />
        </div>
        <div class="footer-icons-button">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/Linkedin1.png?alt=media&token=d76a56c3-a4b6-48ed-84e6-cc26a5b69aab"
            alt="linkedIn-logo"
            class="footer-icons-img"
            @click="
              openLink(
                'https://www.linkedin.com/company/learnspanishonlinecomligo'
              )
            "
          />
        </div>
        <div class="footer-icons-button">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/youtube.png?alt=media&token=dde3368e-8995-43c8-b3f5-1d899e2ac590"
            alt="youtube-logo"
            class="footer-icons-img-small"
            @click="openLink('https://www.facebook.com/ComligoSpanish')"
          />
        </div>
        <div class="footer-icons-button">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/comligo-website-v2.appspot.com/o/instagram.png?alt=media&token=88662424-1e2d-49fe-8e5a-6f258930d1d7"
            alt="instagram-logo"
            class="footer-icons-img-small"
            @click="openLink('https://www.instagram.com/comligospanish/')"
          />
        </div>
      </div>
      <div class="footer-copyright-mobile">
        © 2025 Comligo. All rights reserved.
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "FooterComponent",
  methods: {
    ...mapMutations(["setConsent", "setDialog"]),
    openLink(link) {
      window.open(`${link}`, "_blank").focus();
    },
  },
  data() {
    return {
      footer: [
        {
          title: "Courses",
          link: "/spanish-courses-overview",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Individuals",
          link: "/private-students",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Organizations",
          items: [
            {
              title: "For K-12 education",
              link: "/k-12-schools",
            },
            {
              title: "For IB schools",
              link: "/ib-schools",
            },
            {
              title: "For higher education",
              link: "/higher-education",
            },
            {
              title: "For private sector",
              link: "/private-sector",
            },
            {
              title: "For public sector and non-profits",
              link: "/private-sector",
            },
          ],
          link: "",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
        {
          title: "Pricing",
          link: "/spanish-course-pricing",
          icon: "mdi mdi-chevron-left",
          active: false,
        },
      ],
    };
  },
};
</script>

<style></style>
